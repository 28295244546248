export const isLocal = process.env.NODE_ENV === "development";
export const isTestnet = false;

const mockTelegramUser = {
  id: "9550234",
  username: "user_test",
  url: "https://avatars.mds.yandex.net/i?id=9f3478544faee59cb96218928386b478f3c16327-9880043-images-thumbs&n=13",
  is_premium: false,
  chat_id: "123123",
};

export const localConfig = {
  isLocal,
  user: isLocal ? mockTelegramUser : undefined,
  apiUrl: process.env.API_URL,
  wsUrl: process.env.WS_URL,
};
